import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displaysize'
})
export class DisplaysizePipe implements PipeTransform {

  transform(sizeInBytes: number, ...args: unknown[]): unknown {
    const megaBytes = sizeInBytes / (1024 * 1024);
    return megaBytes.toFixed(2) + ' MB';
  }

}
