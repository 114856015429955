import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-projection',
  templateUrl: './add-projection.component.html',
  styleUrls: ['./add-projection.component.css']
})
export class AddProjectionComponent {
  url:any = "";
  projection_caption:any="";
  file:any;
  filePath:any="";
  errorMsg="";
  discardFlag=false;
  submitFlag=false;
  successFlg=false;
  isLoading=false;

  constructor(private _userService:UserService,private _generalService:GeneralService,public dialogRef: MatDialogRef<AddProjectionComponent>){

  }

  ngOnInit() {
    this.dialogRef.addPanelClass('add-projection-modal');
  }

  addPost(){
    if(this.projection_caption.trim()=="" && !this.file){
      this.errorMsg="Plese select file/Enter the caption";
      return;
    }
    this.submitFlag=true;
    this.isLoading=true;
    if(this.file){
      this.uploadFile();
    }else{
      this.addProjection();
    }
  }
  onDiscardClick(){
    this.discardFlag=true;
  }
  uploadFile(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.file);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.addProjection();
          }else{
            this.isLoading=false;
            this.successFlg=false;
            this.submitFlag=false;
          }
        }else{
          this.isLoading=false;
          this.successFlg=false;
          this.submitFlag=false;
        }
      }else{
        
      }
    }
    );
  }
  close(): void {
    this.dialogRef.close();
  }
  addProjection(){
    let projData = {
      created_by: localStorage.getItem("mad_id"), // temporarily use mad id
      shared_from_id: localStorage.getItem("mad_id"), // temporarily use mad id
      description: this.projection_caption.trim(),
      title: "",
      attachment_type: this.filePath.split(".").pop(),
      has_attachment: this.filePath?true:false,
      attachment_path: this.filePath,
      likes: 0,
      is_posted: true,
      requestType: "create",
  }
  this._userService.addPost(projData)
  .subscribe((data:any) => {
    if(data){
      console.log(data);
      if(data.status){
        if(data.status.code=="00"){
          this.successFlg=true;
          this.isLoading=false;
          console.log("Posted");
          this.close();
        }else{
          this.isLoading=false;
          this.successFlg=false;
          this.submitFlag=false;
        }
      }else{
        this.isLoading=false;
        this.successFlg=false;
        this.submitFlag=false;
      }
    }else{
      
    }
  }
  );

  }
  
  onSelectFile(event: any) {
    this.errorMsg="";
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
      if(this.file.size>59000000){
        this.errorMsg="File size should be less than 10 Bytes";
        this.file="";
        this.url="";
      }else{
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event:any) => { // called once readAsDataURL is completed
          this.url = event.target.result;
        }  
      }
    }
  }
  cancelUpload(){
    this.file="";
    this.url="";
  }

}
