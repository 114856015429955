import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ViewProfilePhotoComponent } from 'src/app/popups/view-profile-photo/view-profile-photo.component';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileComponent {
  @Input() userData: any = {};
  isLoading=true;
  loggedUserData: any;
  logged_mad_id = "";
  first_name = "";
  last_name = "";
  tagline = "";
  username = "";
  about = "";

  github="";
  google_scholar="";
  medium="";
  youtube="";
  website="";
  profile_photo_path="";




  constructor(private _generalService: GeneralService,
    private _userService:UserService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    if (localStorage.getItem("mad_user")) {
      this.loggedUserData = JSON.parse(localStorage.getItem('mad_user') || '{}');
      this.logged_mad_id = this.loggedUserData.mad_id;
    }

  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  openProfile() {
    if (this.userData.mad_id == this.logged_mad_id) {
      const dialogRef = this.dialog.open(ViewProfilePhotoComponent, {
        data: this.userData,
        width: '40%',
        height: '73%',
        backdropClass: "bdrop",
        position: {
          'bottom': '0px',
          'left': '35%',
          'right': '0px',
          'top': '6.5%'
        }
      }
      );
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.userData.profile_photo_path=result;
          this.updateLocalStorage();
        }
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  editProfile() {
    const param={
      "first_name": this.userData.first_name,
      "last_name": this.userData.last_name,
      "tagline": this.userData.tagline,
      "profile_photo_path": this.userData.profile_photo_path,
      "bio":this.userData.bio,
      "github":this.userData.github,
      "google_scholar":this.userData.google_scholar,
      "medium":this.userData.medium,
      "youtube":this.userData.youtube,
      "website":this.userData.website,
  };
    this._userService.editProfile(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            console.log("profile edited");
            this.updateLocalStorage();
          }
        }
      }else{
        
      }
    }
    );
  }
  updateLocalStorage(){
    localStorage.setItem("mad_user",JSON.stringify(this.userData));
    let path="/home/profile/"+this.userData.user_name;
    window.location.reload();
  }

}
