<!-- <div class="feed">
    <div class="chat">
        <div class="chat-header">
            <div class="chat-haeder-text-box">
                <p class="chat-header-text">Chat system</p>
            </div>
            <div class="header-icon-box">
                <svg class="header-loader" width="21" height="21" viewBox="0 0 21 21" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="21" height="21" rx="10.5" fill="#252525" />
                </svg>
            </div>
        </div>
        <div class="chat-content">
            <div class="chat-coming-soon-box">
                <div class="coming-soon-icon-box">
                    <svg class="coming-soon-icon" width="273" height="199" viewBox="0 0 273 199" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M119.258 3C119.258 4.65517 117.916 5.99694 116.261 5.99694C114.606 5.99694 113.264 4.65517 113.264 3C113.264 1.34483 114.606 0.0030582 116.261 0.0030582C117.916 0.0030582 119.258 1.34483 119.258 3ZM45.0834 85.0413C45.0834 86.6964 43.7416 88.0382 42.0864 88.0382C40.4313 88.0382 39.0895 86.6964 39.0895 85.0413C39.0895 83.3861 40.4313 82.0443 42.0864 82.0443C43.7416 82.0443 45.0834 83.3861 45.0834 85.0413ZM116.261 3.56193H51.0773V2.43807H116.261V3.56193ZM42.6484 11.9908V85.0413H41.5245V11.9908H42.6484ZM51.0773 3.56193C46.4221 3.56193 42.6484 7.33567 42.6484 11.9908H41.5245C41.5245 6.71499 45.8014 2.43807 51.0773 2.43807V3.56193Z"
                            fill="#242424" />
                        <path d="M65.6895 89.5371V174.95" stroke="url(#paint0_linear_2739_31416)"
                            stroke-width="1.12385" stroke-dasharray="2.25 2.25" />
                        <g filter="url(#filter0_d_2739_31416)">
                            <path
                                d="M5.00098 42.3356C5.00098 41.0942 6.00731 40.0879 7.24868 40.0879H18.4872H160.093C164.438 40.0879 167.96 43.6101 167.96 47.9549V82.7943C167.96 87.1391 164.438 90.6613 160.093 90.6613H12.868C8.52315 90.6613 5.00098 87.1391 5.00098 82.7943V42.3356Z"
                                fill="#B5B5B5" />
                            <path
                                d="M5.5629 42.3356C5.5629 41.4046 6.31765 40.6498 7.24868 40.6498H18.4872H160.093C164.127 40.6498 167.398 43.9204 167.398 47.9549V82.7943C167.398 86.8288 164.127 90.0994 160.093 90.0994H12.868C8.8335 90.0994 5.5629 86.8288 5.5629 82.7943V42.3356Z"
                                stroke="url(#paint1_linear_2739_31416)" stroke-opacity="0.4"
                                stroke-width="1.12385" />
                        </g>
                        <g filter="url(#filter1_d_2739_31416)">
                            <path
                                d="M250.002 128.872C250.002 127.63 248.996 126.624 247.754 126.624H236.516H94.9102C90.5654 126.624 87.0432 130.146 87.0432 134.491V168.207C87.0432 172.551 90.5654 176.074 94.9102 176.074H242.135C246.48 176.074 250.002 172.551 250.002 168.207V128.872Z"
                                fill="#282828" />
                            <path
                                d="M249.44 128.872C249.44 127.941 248.685 127.186 247.754 127.186H236.516H94.9102C90.8757 127.186 87.6052 130.457 87.6052 134.491V168.207C87.6052 172.241 90.8757 175.512 94.9102 175.512H242.135C246.169 175.512 249.44 172.241 249.44 168.207V128.872Z"
                                stroke="#3D3D3D" stroke-width="1.12385" />
                        </g>
                        <circle cx="17.9242" cy="53.0121" r="7.30505" fill="#3C3C3C" />
                        <circle cx="17.9273" cy="50.4039" r="2.60894" fill="#B9B9B9" />
                        <ellipse cx="17.9227" cy="56.1421" rx="4.6961" ry="2.60894" fill="#B9B9B9" />
                        <path d="M29.7246 76.0508L152.225 76.0508" stroke="#505050" stroke-width="5.61927"
                            stroke-linecap="round" />
                        <path d="M106.146 160.34L228.646 160.34" stroke="#505050" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M106.146 151.349L228.646 151.349" stroke="#505050" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M106.146 142.357L228.646 142.357" stroke="#505050" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M29.7246 52.4492H65.6879" stroke="#808080" stroke-width="3.37156"
                            stroke-linecap="round" />
                        <path
                            d="M76.9272 137.301L82.5464 139.983V133.495L76.9272 136.177V137.301ZM15.5126 95.8829C15.2931 95.6635 14.9373 95.6635 14.7179 95.8829L11.1418 99.459C10.9224 99.6785 10.9224 100.034 11.1418 100.254C11.3613 100.473 11.7171 100.473 11.9365 100.254L15.1152 97.075L18.294 100.254C18.5134 100.473 18.8692 100.473 19.0887 100.254C19.3081 100.034 19.3081 99.6785 19.0887 99.459L15.5126 95.8829ZM77.4891 136.177H19.6107V137.301H77.4891V136.177ZM15.6772 132.244V96.2803H14.5533V132.244H15.6772ZM19.6107 136.177C17.4382 136.177 15.6772 134.416 15.6772 132.244H14.5533C14.5533 135.037 16.8176 137.301 19.6107 137.301V136.177Z"
                            fill="#242424" />
                        <path
                            d="M179.199 56.3833L173.58 53.701V60.1895L179.199 57.5072V56.3833ZM245.109 121.402C245.329 121.622 245.685 121.622 245.904 121.402L249.48 117.826C249.7 117.607 249.7 117.251 249.48 117.031C249.261 116.812 248.905 116.812 248.686 117.031L245.507 120.21L242.328 117.031C242.109 116.812 241.753 116.812 241.533 117.031C241.314 117.251 241.314 117.607 241.533 117.826L245.109 121.402ZM178.638 57.5072H236.516V56.3833H178.638V57.5072ZM244.945 65.9361V121.005H246.069V65.9361H244.945ZM236.516 57.5072C241.171 57.5072 244.945 61.2809 244.945 65.9361H246.069C246.069 60.6602 241.792 56.3833 236.516 56.3833V57.5072Z"
                            fill="#242424" />
                        <defs>
                            <filter id="filter0_d_2739_31416" x="0.505564" y="35.5925" width="171.95"
                                height="59.5641" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="2.24771" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                    result="effect1_dropShadow_2739_31416" />
                                <feBlend mode="normal" in="SourceGraphic"
                                    in2="effect1_dropShadow_2739_31416" result="shape" />
                            </filter>
                            <filter id="filter1_d_2739_31416" x="64.5659" y="104.147" width="207.913"
                                height="94.4033" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="11.2385" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                    result="effect1_dropShadow_2739_31416" />
                                <feBlend mode="normal" in="SourceGraphic"
                                    in2="effect1_dropShadow_2739_31416" result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_2739_31416" x1="66.1895" y1="89.5371"
                                x2="66.1895" y2="174.95" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#484848" />
                                <stop offset="1" stop-color="#242424" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_2739_31416" x1="17.001" y1="46.5" x2="169.001"
                                y2="89.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#10776B" />
                                <stop offset="1" stop-color="#002D85" />
                                <stop offset="1" stop-color="#404040" />
                            </linearGradient>
                        </defs>
                    </svg>

                </div>
                <div class="coming-soon-text-box">
                    <p class="coming-soon-text">Messages coming soon!</p>
                </div>
            </div>
        </div>
        <div class="chat-footer">
            <div class="chat-footer-box-1"></div>
            <div class="chat-footer-box-2">
                <div class="chat-footer-box-3"></div>
            </div>
        </div>
    </div>
</div> -->
<div class="feed">
  <div class="chat" *ngIf="screen=='list'">
    <div class="chat-header">
      <div class="chat-header-text-box">
        <p class="chat-header-text">Messages</p>
      </div>
    </div>
    <div class="chat-content no-scrollbar">
      <div class="message-wrapper" *ngFor="let chat of chatList">
        <div class="message-outer" (click)="openChat(chat)" [ngClass]="getChatClass(chat.unread_message_count)">
          <div class="message-inner">
            <div class="message-header">
              <div class="message-author">
                <img width="32px" height="32px" [src]="getDownloadUrl(chat.profile_photo_path)" />
                <div class="message-author-profile">
                  <div class="message-author-name">{{chat.first_name}} {{chat.last_name}}</div>
                  <div class="message-author-tagline">
                    {{chat.tagline}}
                  </div>
                </div>
              </div>
              <div class="message-timestamp">{{chat.last_message_time | timeAgo}}</div>
            </div>
            <div class="message-details">
              <svg *ngIf="chat.unread_message_count>0" width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#212121" />
                <path
                  d="M10.5 6C10.5 5.72386 10.2761 5.5 10 5.5C9.72386 5.5 9.5 5.72386 9.5 6H10.5ZM9.64645 14.3536C9.84171 14.5488 10.1583 14.5488 10.3536 14.3536L13.5355 11.1716C13.7308 10.9763 13.7308 10.6597 13.5355 10.4645C13.3403 10.2692 13.0237 10.2692 12.8284 10.4645L10 13.2929L7.17157 10.4645C6.97631 10.2692 6.65973 10.2692 6.46447 10.4645C6.2692 10.6597 6.2692 10.9763 6.46447 11.1716L9.64645 14.3536ZM9.5 6V14H10.5V6H9.5Z"
                  fill="#646464" />
              </svg>
              <!-- <svg *ngIf="chat.unread_message_count==0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#212121" />
                <path
                  d="M10.5 6C10.5 5.72386 10.2761 5.5 10 5.5C9.72386 5.5 9.5 5.72386 9.5 6H10.5ZM9.64645 14.3536C9.84171 14.5488 10.1583 14.5488 10.3536 14.3536L13.5355 11.1716C13.7308 10.9763 13.7308 10.6597 13.5355 10.4645C13.3403 10.2692 13.0237 10.2692 12.8284 10.4645L10 13.2929L7.17157 10.4645C6.97631 10.2692 6.65973 10.2692 6.46447 10.4645C6.2692 10.6597 6.2692 10.9763 6.46447 11.1716L9.64645 14.3536ZM9.5 6V14H10.5V6H9.5Z"
                  fill="#646464" />
              </svg> -->
              <div class="message-description" *ngIf="chat.unread_message_count==0" style="color: #919191;">
                {{chat.message_content}}
              </div>
              <div class="message-description" *ngIf="chat.unread_message_count==1">
                <b>{{chat.unread_message_count}} New Message</b>
              </div>
              <div class="message-description" *ngIf="chat.unread_message_count>1">
                <b>{{chat.unread_message_count}} New Messages</b>
              </div>
              <!-- <div class="message-unseen-count" *ngIf="chat.unread_message_count>0">{{chat.unread_message_count}}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat" *ngIf="screen=='single'">
    <div class="chat-individual-header">
      <div class="chat-individual-header-icon" style="cursor: pointer;" (click)="back()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect width="24" height="24" />
          <g filter="url(#filter0_d_380_1897)">
            <rect x="22" y="22" width="20" height="20" rx="10" transform="rotate(-180 22 22)" fill="#181818"/>
            <rect x="21.5833" y="21.5833" width="19.1667" height="19.1667" rx="9.58333" transform="rotate(-180 21.5833 21.5833)" stroke="#212121" stroke-width="0.833333"/>
          </g>
          <g filter="url(#filter1_d_380_1897)">
            <path d="M16 12.5C16.2761 12.5 16.5 12.2761 16.5 12C16.5 11.7239 16.2761 11.5 16 11.5V12.5ZM7.64644 11.6464C7.45118 11.8417 7.45118 12.1583 7.64644 12.3536L10.8284 15.5355C11.0237 15.7308 11.3403 15.7308 11.5355 15.5355C11.7308 15.3403 11.7308 15.0237 11.5355 14.8284L8.7071 12L11.5355 9.17157C11.7308 8.97631 11.7308 8.65973 11.5355 8.46447C11.3403 8.2692 11.0237 8.2692 10.8284 8.46447L7.64644 11.6464ZM16 11.5L8 11.5V12.5L16 12.5V11.5Z" fill="#D7D7D7"/>
          </g>
          <defs>
            <filter id="filter0_d_380_1897" x="1.16667" y="1.16667" width="21.6667" height="21.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feMorphology radius="0.833333" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_380_1897"/>
              <feOffset/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.329785 0 0 0 0 0.329785 0 0 0 0 0.329785 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_380_1897"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_380_1897" result="shape"/>
            </filter>
            <filter id="filter1_d_380_1897" x="3.5" y="8.31787" width="17" height="15.3643" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_380_1897"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_380_1897" result="shape"/>
            </filter>
          </defs>
        </svg>
      </div>
      <div class="message-author-wrapper">
        <div class="message-author">
          <img width="32px" height="32px" [src]="getDownloadUrl(current_chat.profile_photo_path)" />
          <div class="message-author-profile">
            <div class="message-author-name">{{current_chat.first_name}} {{current_chat.last_name}}</div>
            <div class="message-author-tagline">
              {{current_chat.tagline}}
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div #scrollContainer class="chat-messages-list no-scrollbar">
      <div *ngFor="let message of messagesList" [ngClass]="getClass(message.sender_id)">
        <p class="message-body" [innerHTML]="getHtmlString(message.message_content)">
        </p>
        <div class="timestamp">{{message.timestamp | timeAgo}}</div>
      </div>
    </div>
    <div>
      <div class="chat-footer">
        <textarea id="message-input" rows="1" class="message-input no-scrollbar"
          placeholder="Write message here" [(ngModel)]="messageText"></textarea>
        <svg (click)="sendMessage()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="#181818" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#212121" />
          <path
            d="M9.18866 7.65476L24.2224 15.45C24.954 15.8294 24.9384 16.8813 24.1959 17.2388L9.261 24.4297C8.34646 24.87 7.43351 23.8252 7.99247 22.978L12.3513 16.3709L7.87563 9.06489C7.34647 8.20112 8.28939 7.18847 9.18866 7.65476Z"
            fill="white" />
          <path
            d="M12.3513 16.3709L7.87563 9.06489C7.34647 8.20112 8.28939 7.18847 9.18866 7.65476L24.2224 15.45C24.954 15.8294 24.9384 16.8813 24.1959 17.2388L9.261 24.4297C8.34646 24.87 7.43351 23.8252 7.99247 22.978L12.3513 16.3709ZM12.3513 16.3709H19.7127"
            stroke="#212121" />
        </svg>
      </div>  
    </div>
  </div>
</div>