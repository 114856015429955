<div class="feed" (scroll)="scrollHandler($event)">
  <div class="forumsWrappper">
    <div class="forums_header_box">
      <p class="forums_header">Participate in forum discussions by sharing thoughts, raising doubts and asking
        questions to the community.</p>
    </div>
    <div class="forums">
      <app-forum [forum]="forum" *ngFor="let forum of forumList"></app-forum>
    </div>
  </div>
</div>