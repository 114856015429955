import { Component, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileReportComponent } from 'src/app/popups/profile-report/profile-report.component';
import { ViewProfilePhotoComponent } from 'src/app/popups/view-profile-photo/view-profile-photo.component';
import { CommunityService } from 'src/app/services/community.service';
import { ForumService } from 'src/app/services/forum.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectionService } from 'src/app/services/projection.service';
import { UserService } from 'src/app/services/user.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  current_view = "projection";
  userData: any = {};
  loggedUserData: any = {};
  public mad_id: string="";
  public username: string="";
  logged_mad_id:string="";
  feed: any = [];
  isLoading=true;
  search_text="";
  projectionsList=[];
  usersList=[];
  writeupsList=[];
  forumsList=[];

  constructor(private _userService: UserService,
    private router: Router,
    private _projectionService: ProjectionService,
    private _generalService: GeneralService,
    private _forumService: ForumService,
    private _writeupService: WriteupService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private _communityService:CommunityService
  ) {

  }

  ngOnInit(): void {
    const search_text = this.route.snapshot.paramMap.get('search_text');
    if(search_text){
      this.search_text=search_text;
      this.fetchSearchAll();
    }
  }
  ngOnDestroy(): void {
    const parentElement = this.elRef.nativeElement.parentElement;
    if (parentElement) {
      // parentElement.style.display = 'block';
      parentElement.style.marginBottom = '0rem';
    }
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  openProfile() {
    if(this.mad_id==this.logged_mad_id){
      const dialogRef = this.dialog.open(ViewProfilePhotoComponent,{
        data: this.userData,
        width: '40%',
        height:'73%',
        backdropClass: "bdrop",
        position:{'bottom': '0px',
        'left': '35%',
        'right': '0px',
        'top': '6.5%'}
      }
      );
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  changeTab(tab: any) {
    console.log(tab);
    if (tab == "projection") {
      this.feed=this.projectionsList;
    } else if (tab == "writeup") {
      this.feed=this.writeupsList;
    } else if (tab == "forum") {
      this.feed=this.forumsList;
    } else if (tab == "user") {
      this.feed=this.usersList;
    }
    console.log(this.feed);
    this.current_view=tab;
  }
  navigateTo(path: any) {
    this.router.navigate(["home", path]);
  }
  fetchSearchAll() {
    const param = {
      "text":this.search_text,
      "userId":localStorage.getItem('mad_id')
  }
    this._projectionService.fetchSearchAll(param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.projectionsList=data.projections;
          this.usersList=data.users;
          this.forumsList=data.forums;
          this.writeupsList=data.writeups;
          this.changeTab("projection");
        } else {
          this.projectionsList=[];
          this.usersList=[];
          this.forumsList=[];
          this.writeupsList=[];
        }
      }
      );
  }
  fetchSearchWriteups() {
    const param = {
      "text":"bhanu"
  }
    this._projectionService.fetchSavedWriteups(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response) {
              if (data.response.list) {
                this.feed = data.response.list;
              }
            }
          }
        } else {

        }
      }
      );

  }
  fetchSearchForums() {
    const param = {
      "text":"bhanu"
  }
    this._projectionService.fetchSavedForums(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response) {
              // this.feed = data.response;
              if (data.response.list) {
                this.feed = data.response.list;
              }
            }
          }
        } else {

        }
      }
      );

  }
  

  report(){
    const dialogRef = this.dialog.open(ProfileReportComponent,{
      data: this.userData,
      width: '55%',
      height:'45%',
      backdropClass: "",
      position:{'bottom': '0px',
      'left': '30%',
      'right': '0px',
      'top': '15%'}
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  follow(mad_id:any){
    let projData = {
      following_id: mad_id
    }
    this._communityService.followUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("followed");
              const index = this.feed.findIndex((record: any) => record.mad_id == mad_id);
              if (index !== -1) {
                  this.feed[index].is_following = 1;
              }
            }
          }
        }else{
          
        }
      }
      );
  }
  unfollow(mad_id:any){
    let projData = {
      follower_id: mad_id
    }
    this._communityService.unfollowUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("unfollowed");
              const index = this.feed.findIndex((record: any) => record.mad_id == mad_id);
              if (index !== -1) {
                  this.feed[index].is_following = 0;
              }
            }
          }
        }else{
          
        }
      }
      );
  }

}
