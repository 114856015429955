import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';
import { AddProjectionComponent } from '../add-projection/add-projection.component';

@Component({
  selector: 'app-edit-projection',
  templateUrl: './edit-projection.component.html',
  styleUrls: ['./edit-projection.component.css']
})
export class EditProjectionComponent {
  projection_caption:any="";
  errorMsg="";
  discardFlag=false;
  submitFlag=false;
  successFlg=false;
  projection:any;

  constructor(private _userService:UserService,private _generalService:GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddProjectionComponent>){
      this.projection=data.projection;
      this.projection_caption=data.projection.description;
  }

  ngOnInit() {
    this.dialogRef.addPanelClass('add-projection-modal');
  }

  onDiscardClick(){
    this.discardFlag=true;
  }
  close(): void {
    this.dialogRef.close();
  }
  editPost(){
    if(this.projection_caption.trim()==""){
      this.errorMsg="Plese select file/Enter the caption";
      return;
    }
    this.submitFlag=true;
    let projData = {
      post_id:this.projection.projection_id,
      description: this.projection_caption.trim(),
      title:this.projection.title
  }
  this._userService.editPost(projData)
  .subscribe((data:any) => {
    if(data){
      console.log(data);
      if(data.status){
        if(data.status.code=="00"){
          this.successFlg=true;
          console.log("edited");
          this.close();
        }
      }
    }else{
      
    }
  }
  );

  }
  
}
