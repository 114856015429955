import { Component, ViewChild } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent {

  preload: string = 'auto';
  api: VgApiService = new VgApiService;
  playing:boolean=false;

  @ViewChild('editor') editor: any;

  name = 'Angular';
  conent:any;
  htmlstring='';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image']                         // link and image, video
    ]
  };
  onContentChanged($event: any){
    console.log($event);
    this.htmlstring=$event.html;
  }

  logChange($event: any) {
    console.log(this.editor);
    console.log($event);
  }
  constructor() {}

  onPlayerReady(api: VgApiService) {
      this.api = api;
      this.api.getDefaultMedia().subscriptions.ended.subscribe(
        () => {
            // Set the video to the beginning
            this.api.getDefaultMedia().currentTime = 0;
        }
    );
    
  }
  pause(){
    if(this.playing){
      this.playing=!this.playing;
      this.api?.pause();    
    }else{
      this.playing=!this.playing;
      this.api?.play();
    }
  }
  back10Sec(){
    this.api.getDefaultMedia().currentTime = this.api.getDefaultMedia().currentTime-10;
    this.api?.play();
    this.playing=true;
  }
  front10Sec(){
    this.api.getDefaultMedia().currentTime = this.api.getDefaultMedia().currentTime+10;
    this.api?.play();
    this.playing=true;
  }



  currentPage=1;
  totalPages=0;
  progress=0;
  style="width: 50%;height: 2.5px;background-color: #d9d9d9;"
  next(){
    this.currentPage+=1;
    this.progress=this.currentPage/this.totalPages*100;
    this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
  }
  back(){
    this.currentPage-=1;
    this.progress=this.currentPage/this.totalPages*100;
    this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
  }

  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    console.log(progressData);
  }
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }
  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
    console.log('(page-loaded)', pdf);
    console.log(pdf._pdfInfo.numPages);
    this.totalPages=pdf._pdfInfo.numPages;
    this.progress=this.currentPage/this.totalPages*100;
    this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
 }
 

}
