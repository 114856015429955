import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-add-writeup',
  templateUrl: './add-writeup.component.html',
  styleUrls: ['./add-writeup.component.css'],
})
export class AddWriteupComponent {
  submitFlag = false;
  successFlg = false;
  isLoading=false;


  type="new";
  writeup:any;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      // [{ 'color': ['white'] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link', 'image'], // link and image, video
    ],
  };
  title = '';
  htmlstring = '';
  discardFlag = false;

  constructor(
    private _writeupService: WriteupService,
    public dialogRef: MatDialogRef<AddWriteupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.type=data.type;
      if(this.type=="edit"){
        this.writeup=data.writeup;
        this.title=this.writeup.title;
        this.htmlstring=this.writeup.data;  
      }
    }
  maxLength: number = 30000;
  characterCount: number = 0;

  ngOnInit() {
    this.dialogRef.addPanelClass('add-writeup-modal');
  }

  onContentChanged($event: any) {
    console.log($event);
    this.htmlstring = $event.html;

    const content = $event?.editor?.getText();
    this.characterCount = content ? content.length - 1 : 0;

    if (this.characterCount > this.maxLength) {
      $event.editor.deleteText(this.maxLength, $event.editor.getLength());
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  post() {
    if(this.title=="" || this.htmlstring==""){
      return;
    }
    this.submitFlag = true;
    this.isLoading=true;
    let param = {
      title: this.title,
      data: this.htmlstring,
      created_by: localStorage.getItem('mad_id'),
    };
    this._writeupService.createWriteup(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status) {
          if (data.status.code == '00') {
            this.successFlg = true;
            console.log('Posted');
            this.isLoading=false;
            this.close();
          }else{
            this.isLoading=false;
            this.successFlg=false;
            this.submitFlag=false;
          }
        }else{
          this.isLoading=false;
          this.successFlg=false;
          this.submitFlag=false;
        }
      } else {
      }
    });
  }
}

