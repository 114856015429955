// home-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostComponent } from './components/post/post.component';
import { CommunityComponent } from './pages/community/community.component';
import { FeedComponent } from './pages/feed/feed.component';
import { ForumsComponent } from './pages/forums/forums.component';
import { HelpComponent } from './pages/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { WriteupsComponent } from './pages/writeups/writeups.component';
import { InviteComponent } from './pages/invite/invite.component';
import { SearchComponent } from './pages/search/search.component';
import { PublicViewComponent } from './pages/public-view/public-view.component';

const homeRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      // { path: '', redirectTo: 'feed', pathMatch: 'full' },
      { path: '', component: FeedComponent },
      { path: 'community', component: CommunityComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'writeups', component: WriteupsComponent },
      { path: 'forums', component: ForumsComponent },
      { path: 'help', component: HelpComponent },
      { path: 'messages/:username', component: MessagesComponent },
      { path: 'messages', component: MessagesComponent },
      { path: 'profile/:username', component: ProfileComponent },
      { path: 'page', component: PostComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'invite', component: InviteComponent },
      { path: 'search/:search_text', component: SearchComponent },
      { path: 'public/:hashText', component: PublicViewComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
