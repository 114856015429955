import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit{
  year = new Date().getFullYear();
  @Input() notificationCount: any = {};

  @Output() navigateEvent = new EventEmitter<string>();

  ngOnInit(): void {
  }
  navigateTo(path:string){
    this.navigateEvent.emit(path)
  }
}
