<link
  href="https://res.cloudinary.com/dwfkpyc0n/raw/upload/v1696692352/style_zd5oie_h2mcgv.css"
  rel="stylesheet"
  crossorigin="anonymous"
/>
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
  href="https://fonts.googleapis.com/css2?family=Space+Mono&display=swap"
  rel="stylesheet"
/>
<link
  href="https://fonts.googleapis.com/css2?family=Exo+2:wght@600&display=swap"
  rel="stylesheet"
/>
<link
  href="https://fonts.googleapis.com/css2?family=Exo+2:wght@500&display=swap"
  rel="stylesheet"
/>
<link
  href="https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400&display=swap"
  rel="stylesheet"
/>

<body
  data-bs-spy="scroll"
  data-bs-target="#site-navbar-target"
  data-bs-offset="100"
  class="bg-light"
>
  <nav
    class="site-nav js-site-navbar site-navbar-target"
    id="site-navbar-target"
  >
    <div class="container site-navigation">
      <div class="row">
        <div class="col-6 col-md-2 col-lg-2 logo-box">
          <a (click)="gotoHome()" class="logo m-0 float-start img-fluid"
            ><img src="assets/images/logo.svg" alt="Logo"
          /></a>
        </div>
        <div class="col-md-8 d-none d-md-block col-lg-4 text-center"></div>
        <div class="col-6 col-md-2 col-lg-6">
          <ul
            class="js-clone-nav d-none d-lg-inline-block text-end float-end site-menu p-0 m-0"
          >
            <li style="padding-right: 5px; font-family: 'Exo 2', sans-serif">
              Already have an account?
            </li>
            <li>
              <a
                (click)="navigateTo('signin')"
                class="btn-mad"
                style="
                  font-family: 'Exo 2', sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 93.5%;
                "
                >Login</a
              >
            </li>
          </ul>
          <a
          (click)="navigateTo('signin')"
            class="btn-mad d-lg-none d-xl-done d-xl-none d-xxl-none"
            style="
              font-family: 'Exo 2', sans-serif;
              color: white;
              width: 100px;
              float: right;
            "
            >Login</a
          >
        </div>
      </div>
    </div>
  </nav>
  <div class="hero bg-dark" id="home-section">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 mx-auto d-flex align-items-center justify-content-center vh-100"
        >
          <div class="login-box" *ngIf="step == 1">
            <div class="progressbar"></div>
            <div class="progressbarupdate"></div>
            <div class="login-light text-center">
              <p>Forgot password</p>
            </div>
            <div class="login-form-box">
              <form class="contact-form" autocomplete="off">
                <div class="mb-3">
                  <label class="text-black testusername">Email</label>
                  <label *ngIf="emailValidFlg"
                    class="errorusername"
                    >Invalid email</label
                  >
                  <label *ngIf="userValidFlg"
                    class="errorusername"
                    >User doesn't exist</label
                  >
                  <input
                    type="text"
                    id="mademail"
                    class="form-control"
                    autocomplete="off"
                    name="mademail"
                    [(ngModel)]="mademail"
                    (input)="validateEmail()"
                  />
                </div>
                <button
                  type="submit"
                  class="btn-mad"
                  (click)="gotostep(2)"
                  style="
                    width: 100%;
                    font-family: 'Exo 2', sans-serif;
                    color: white;
                  "
                >
                  <p>
                    <label *ngIf="!isLoading1">Send code</label>
                    <img *ngIf="!isLoading1"
                      src="assets/images/arrow.svg"
                      style="
                        display: inline-block;
                        padding-right: 10px;
                        padding-left: 5px;
                      "
                    />
                    <svg *ngIf="isLoading1"
                    style="margin: auto; background: rgba(0, 0, 0, 0); display: inline-block; shape-rendering: auto; width: 14%;margin-top: -45px;padding-top: 0px;border-top-style: solid;margin-bottom: -45px;border-top-width: 0px;margin-left: -20px;"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
        margin:-45px -10px;
        background: rgba(0, 0, 0, 0);
        display: inline-block;
        shape-rendering: auto;
        width: 15%;
      " viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.9444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(20 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8888888888888888s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(40 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(80 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(100 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6111111111111112s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(140 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.5555555555555556s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(160 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.4444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(200 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3888888888888889s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(220 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(260 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(280 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.1111111111111111s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(320 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.05555555555555555s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(340 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                  </svg>
                  </p>
                </button>
              </form>
            </div>
          </div>
          <div class="login-box" *ngIf="step == 2">
            <button class="back_btn" (click)="gotostep(1)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3904 6L10.0477 9.7222C8.65078 10.9195 8.65078 13.0805 10.0477 14.2778L14.3904 18"
                  stroke="white"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <div class="progressbar"></div>
            <div class="progressbarupdateconfirm"></div>
            <div class="login-light text-center">
              <p>Email confirmation</p>
            </div>
            <div class="socials d-flex">
              <div class="flex-fill entercode">
                <label class="verificationcode">Enter verification code</label>
              </div>
              <div class="flex-fill" *ngIf="otpValidationFlg">
                <label class="verificationcode" style="color: red"
                  >Incorrect code</label
                >
              </div>
              <div class="flex-fill" *ngIf="!canResend">
                <label class="verificationcode" style="color: red"
                  >You can resend OTP in {{ countdown }} seconds.</label>
              </div>

            </div>
            <div
              class="row align-items-start socials align-items-start justify-content-start"
            >
              <div class="row">
                <div
                  class="col-md-8 col-lg-8 col-sm-8 col-8"
                  style="display: flex"
                >
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp1
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(1)"
                      [(ngModel)]="optVal1"
                    />
                  </div>
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp2
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(2)"
                      [(ngModel)]="optVal2"
                    />
                  </div>
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp3
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(3)"
                      [(ngModel)]="optVal3"
                    />
                  </div>
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp4
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(4)"
                      [(ngModel)]="optVal4"
                    />
                  </div>
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp5
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(5)"
                      [(ngModel)]="optVal5"
                    />
                  </div>
                  <div class="otpbox">
                    <input
                      type="text"
                      class="otp"
                      #otp6
                      placeholder="-"
                      required
                      maxlength="1"
                      (input)="goto(6)"
                      [(ngModel)]="optVal6"
                    />
                  </div>
                  <a (click)="resendOtp()" class="resendbox" *ngIf="canResend">
                    <p class="float-end" style="">Re-send code</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="login-form-box" style="margin-top: -20px">
              <form class="contact-form" autocomplete="off">
                <br />
                <p class="verificationemail">
                  Check your email for the verification code. If not visible,
                  check the spam folder.
                </p>
                <button
                  type="submit"
                  class="btn-mad"
                  (click)="gotostep(3)"
                  style="
                    width: 100%;
                    font-family: 'Exo 2', sans-serif;
                    color: white;
                  "
                >
                  <p><label *ngIf="!isLoading2">Continue</label>
                    <img
                    *ngIf="!isLoading2"
                      src="assets/images/arrow.svg"
                      style="
                        display: inline-block;
                        padding-right: 10px;
                        padding-left: 5px;
                      "
                    />
                    <svg *ngIf="isLoading2"
                    style="margin: auto; background: rgba(0, 0, 0, 0); display: inline-block; shape-rendering: auto; width: 14%;margin-top: -45px;padding-top: 0px;border-top-style: solid;margin-bottom: -45px;border-top-width: 0px;margin-left: -20px;"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
        margin:-45px -10px;
        background: rgba(0, 0, 0, 0);
        display: inline-block;
        shape-rendering: auto;
        width: 15%;
      " viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.9444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(20 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8888888888888888s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(40 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(80 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(100 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6111111111111112s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(140 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.5555555555555556s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(160 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.4444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(200 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3888888888888889s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(220 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(260 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(280 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.1111111111111111s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(320 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.05555555555555555s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(340 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                  </svg>
                  </p>
                </button>
                <p class="termsnc">
                  By continuing, you agree to our
                  <a href="https://madscientist-tech.notion.site/PRIVACY-POLICIES-35259db25d1c431494b2e7cffe364b8f?pvs=4">privacy policies</a>,
                  <a href="https://madscientist-tech.notion.site/TERMS-OF-SERVICE-5e7f553e19c247ca83ef7e2744516987?pvs=4">terms & conditions</a> and
                  <a href="https://madscientist-tech.notion.site/COMMUNITY-GUIDELINES-247ef1e57b754179a1d2d3c48e10aa96?pvs=4">community Guidelines</a>
                </p>
              </form>
            </div>
          </div>
          <div class="login-box" *ngIf="step == 3">
            <button class="back_btn" (click)="gotostep(1)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3904 6L10.0477 9.7222C8.65078 10.9195 8.65078 13.0805 10.0477 14.2778L14.3904 18"
                  stroke="white"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <div class="progressbar"></div>
            <div class="progressbarupdateconfirm"></div>
            <div class="login-light text-center">
              <p>Set password</p>
            </div>
            <div class="row align-items-start socials align-items-start justify-content-start">
              <div class="row">
                <div class="mb-3">
                  <label class="text-black testusername"
                    >Password
                    <span>[min 6 char, Number, Upper case]</span></label
                  >
                  <label class="errorusername" *ngIf="passwordValidFlg"
                    >Invalid password</label
                  >
                  <input
                    [type]="passType"
                    style="-webkit-text-security: star"
                    autocomplete="off"
                    class="form-control"
                    id="madpassword"
                    name="madpassword"
                    (input)="validatePassword()"
                    [(ngModel)]="madpassword"
                  /><svg
                    onclick="ViewPassword()"
                    id="visibleIcon"
                    style="
                      display: none;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                    "
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.9132 13.3333C18.1452 13.3333 18.3345 13.1451 18.3228 12.9133C18.2748 11.9625 18.0641 11.0258 17.699 10.1443C17.2802 9.13326 16.6664 8.2146 15.8926 7.44078C15.1187 6.66696 14.2001 6.05313 13.189 5.63434C12.178 5.21555 11.0944 5 10 5C8.90567 5 7.82203 5.21555 6.81099 5.63434C5.79994 6.05313 4.88128 6.66696 4.10746 7.44078C3.33364 8.2146 2.71981 9.13326 2.30102 10.1443C1.93589 11.0258 1.72525 11.9625 1.67727 12.9133C1.66558 13.1451 1.8548 13.3333 2.08685 13.3333C2.31891 13.3333 2.5058 13.1451 2.5188 12.9134C2.56598 12.0729 2.7545 11.2454 3.0774 10.4659C3.45395 9.5568 4.00588 8.73078 4.70167 8.03499C5.39746 7.3392 6.22348 6.78727 7.13257 6.41071C8.04166 6.03416 9.01602 5.84034 10 5.84034C10.984 5.84034 11.9584 6.03416 12.8675 6.41071C13.7765 6.78727 14.6026 7.3392 15.2984 8.03499C15.9941 8.73078 16.5461 9.5568 16.9226 10.4659C17.2455 11.2454 17.434 12.0729 17.4812 12.9134C17.4942 13.1451 17.6811 13.3333 17.9132 13.3333ZM12.2543 13.757C13.3751 14.303 14.1667 14.6886 14.1667 13.3333C14.1667 11.0321 12.3012 9.16667 10 9.16667C7.69883 9.16667 5.83335 11.0321 5.83335 13.3333C5.83335 14.6196 6.286 14.3378 7.08713 13.8389C7.71928 13.4454 8.56841 12.9167 9.58335 12.9167C10.5293 12.9167 11.472 13.3759 12.2543 13.757Z"
                      fill="#333333"
                    />
                    <path
                      d="M16.6667 3.3335L2.5 17.5002"
                      stroke="#333333"
                      stroke-width="0.833333"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    onclick="ViewPassword()"
                    id="hiddenIcon"
                    style="
                      display: inline-block;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2982 8.00498C14.6323 7.34573 13.8472 6.81692 12.9842 6.44588C12.9472 6.42996 12.91 6.41433 12.8727 6.39899L12.8673 6.39676C11.9582 6.02393 10.9838 5.83203 9.99982 5.83203C9.01583 5.83203 8.04147 6.02393 7.13238 6.39676L7.12696 6.39899C7.08964 6.41433 7.05246 6.42996 7.01543 6.44588C6.1524 6.81692 5.36731 7.34573 4.70148 8.00498C4.00569 8.69389 3.45376 9.51174 3.0772 10.4118C2.75485 11.1824 2.56641 12.0003 2.51885 12.831C2.50558 13.0627 2.31872 13.2509 2.08666 13.2509C1.85461 13.2509 1.66536 13.0627 1.67729 12.8309C1.72568 11.8909 1.93623 10.9649 2.30083 10.0934C2.71962 9.09239 3.33344 8.18281 4.10727 7.41664C4.85006 6.68119 5.72632 6.0917 6.68964 5.67886C6.72987 5.66162 6.77026 5.64469 6.81079 5.62807L6.81621 5.62585C7.82571 5.21266 8.90743 5 9.99982 5C11.0922 5 12.1739 5.21266 13.1834 5.62585L13.1889 5.62806C13.2294 5.64469 13.2698 5.66162 13.31 5.67886C14.2733 6.0917 15.1496 6.68119 15.8924 7.41664C16.6662 8.18281 17.28 9.09239 17.6988 10.0934C18.0634 10.9649 18.274 11.8909 18.3224 12.8309C18.3343 13.0627 18.145 13.2509 17.913 13.2509C17.6809 13.2509 17.4941 13.0627 17.4808 12.831C17.4332 12.0003 17.2448 11.1824 16.9224 10.4118C16.5459 9.51174 15.994 8.69389 15.2982 8.00498ZM12.2541 13.6705C13.3749 14.2111 14.1665 14.5929 14.1665 13.251C14.1665 10.9726 12.301 9.12557 9.99982 9.12557C7.69864 9.12557 5.83316 10.9726 5.83316 13.251C5.83316 14.5246 6.2858 14.2455 7.08694 13.7517C7.71909 13.362 8.56822 12.8385 9.58316 12.8385C10.5291 12.8385 11.4718 13.2932 12.2541 13.6705Z"
                      fill="white"
                    />
                  </svg>
                  <!-- <img
                    id="passwordicon"
                    src="assets/images/hidden.svg"
                    style="
                      height: 20px;
                      display: inline-block;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                      z-index: 10;
                    "
                    (click)="ViewPassword()"
                  /> -->
                </div>
              </div>
            </div>
            <div class="login-form-box" style="margin-top: -20px">
              <form class="contact-form" autocomplete="off">
                <br />
                <button
                  type="submit"
                  class="btn-mad"
                  (click)="resetPass()"
                  style="
                    width: 100%;
                    font-family: 'Exo 2', sans-serif;
                    color: white;
                  "
                >
                  <p>
                    <label *ngIf="!isLoading3">Reset Password</label>
                    <img
                      *ngIf="!isLoading3"
                      src="assets/images/arrow.svg"
                      style="
                        display: inline-block;
                        padding-right: 10px;
                        padding-left: 5px;
                      "
                    />
                    <svg *ngIf="isLoading3"
                    style="margin: auto; background: rgba(0, 0, 0, 0); display: inline-block; shape-rendering: auto; width: 14%;margin-top: -45px;padding-top: 0px;border-top-style: solid;margin-bottom: -45px;border-top-width: 0px;margin-left: -20px;"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
        margin:-45px -10px;
        background: rgba(0, 0, 0, 0);
        display: inline-block;
        shape-rendering: auto;
        width: 15%;
      " viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.9444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(20 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8888888888888888s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(40 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(80 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.7222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(100 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.6111111111111112s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(140 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.5555555555555556s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(160 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.4444444444444444s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(200 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3888888888888889s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(220 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2777777777777778s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(260 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.2222222222222222s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(280 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.1111111111111111s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(320 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                          begin="-0.05555555555555555s" repeatCount="indefinite"></animate>
                      </rect>
                    </g>
                    <g transform="rotate(340 50 50)">
                      <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                          repeatCount="indefinite">
                        </animate>
                      </rect>
                    </g>
                  </svg>
                  </p>
                </button>
                <p class="termsnc">
                  By continuing, you agree to our
                  <a href="https://madscientist-tech.notion.site/PRIVACY-POLICIES-35259db25d1c431494b2e7cffe364b8f?pvs=4">privacy policies</a>,
                  <a href="https://madscientist-tech.notion.site/TERMS-OF-SERVICE-5e7f553e19c247ca83ef7e2744516987?pvs=4">terms & conditions</a> and
                  <a href="https://madscientist-tech.notion.site/COMMUNITY-GUIDELINES-247ef1e57b754179a1d2d3c48e10aa96?pvs=4">community Guidelines</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
