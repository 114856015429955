<div class="feed no-scrollbar">
    <div class="invite_refer_box">
        <div class="invite">
            <div class="invite_header">
                <div class="invite_header_icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6080_56854)">
                            <rect y="12" width="48" height="36" rx="6" fill="#D9D9D9" />
                            <rect x="12.5" y="0.5" width="23" height="23" rx="11.5" fill="#10776B"
                                stroke="white" />
                            <path d="M24 8V16" stroke="white" stroke-linecap="round" />
                            <path d="M20 12.0117L28 12.0117" stroke="white" stroke-linecap="round" />
                            <path
                                d="M7.5 20L22.0548 27.939C23.265 28.5991 24.7298 28.5895 25.9312 27.9137L40 20"
                                stroke="#10776B" stroke-linecap="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_6080_56854">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <p class="invite_header_text">
                    Make the largest
                    <br />
                    community of technology
                </p>
                <div class="invite_header_animation">
                    <svg width="578" height="289" viewBox="0 0 578 289" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.15">
                            <g filter="url(#filter0_i_6080_56837)">
                                <circle cx="289" r="289" fill="#D9D9D9" fill-opacity="0.1" />
                            </g>
                            <g filter="url(#filter1_i_6080_56837)">
                                <circle cx="289" r="236" fill="#D9D9D9" fill-opacity="0.1" />
                            </g>
                            <g filter="url(#filter2_i_6080_56837)">
                                <circle cx="289" r="189" fill="#D9D9D9" fill-opacity="0.1" />
                            </g>
                            <g filter="url(#filter3_i_6080_56837)">
                                <circle cx="289" r="145" fill="#D9D9D9" fill-opacity="0.1" />
                            </g>
                            <g filter="url(#filter4_i_6080_56837)">
                                <circle cx="289" r="102" fill="#D9D9D9" fill-opacity="0.4" />
                            </g>
                        </g>
                        <defs>
                            <filter id="filter0_i_6080_56837" x="0" y="-289" width="578" height="579"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                    result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                    result="effect1_innerShadow_6080_56837" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="15.9" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0630001 0 0 0 0 0.466667 0 0 0 0 0.418227 0 0 0 1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6080_56837" />
                            </filter>
                            <filter id="filter1_i_6080_56837" x="53" y="-236" width="472" height="473"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                    result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                    result="effect1_innerShadow_6080_56837" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="15.9" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0630001 0 0 0 0 0.466667 0 0 0 0 0.418227 0 0 0 1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6080_56837" />
                            </filter>
                            <filter id="filter2_i_6080_56837" x="100" y="-189" width="378" height="379"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                    result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                    result="effect1_innerShadow_6080_56837" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="15.9" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0630001 0 0 0 0 0.466667 0 0 0 0 0.418227 0 0 0 1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6080_56837" />
                            </filter>
                            <filter id="filter3_i_6080_56837" x="144" y="-145" width="290" height="291"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                    result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                    result="effect1_innerShadow_6080_56837" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="15.9" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0630001 0 0 0 0 0.466667 0 0 0 0 0.418227 0 0 0 1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6080_56837" />
                            </filter>
                            <filter id="filter4_i_6080_56837" x="187" y="-102" width="204" height="205"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                    result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                    result="effect1_innerShadow_6080_56837" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="15.9" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0630001 0 0 0 0 0.466667 0 0 0 0 0.418227 0 0 0 1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6080_56837" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="invite_content">
                <p class="invite_content_text">Let you’re creativity unleash with MadScientist visualise the
                    technoverse</p>
                <p class="invite_content_share_prof_text">Share your profile with people you know</p>
                <div class="invite_content_profile_pic_box">
                <img
                    style="border-radius: 4px;"
                    width="35" height="35"
                    [src]="getDownloadUrl(userData?.profile_photo_path)"
                    alt=""
                />
                </div>
                <button class="invite_content_action_btn">
                    <p>Invite</p>
                </button>
                <div class="invite_content_share_prof_link_box">
                    <a [href]="link" class="invite_content_share_prof_link"
                        target="_blank" rel="noopener noreferrer">
                        <p>{{link}}</p>
                    </a>
                    <button class="invite_content_share_prof_link_copy_btn">
                        <p>copy</p>
                    </button>
                </div>
            </div>
        </div>
        <div class="campus_program">
            <div class="campus_program_bg_img"></div>
            <div class="campus_program_icon">
                <svg width="120" height="68" viewBox="0 0 120 68" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M59.6373 5.06835L12.3593 23.5068C11.5144 23.8363 11.5092 25.0301 12.3513 25.3669L19.0006 28.0266V42.9618C17.805 43.6534 17.0006 44.9461 17.0006 46.4266C17.0006 47.8679 17.7629 49.1312 18.9064 49.8352L17.1249 57.5547C16.5498 60.0471 18.4427 62.4266 21.0006 62.4266C23.5586 62.4266 25.4515 60.0471 24.8763 57.5547L23.0949 49.8352C24.2383 49.1312 25.0006 47.8679 25.0006 46.4266C25.0006 44.9461 24.1962 43.6534 23.0006 42.9618V29.6266L54.4298 42.1983C58.006 43.6288 61.9953 43.6288 65.5715 42.1983L107.65 25.3669C108.492 25.0301 108.487 23.8363 107.642 23.5068L60.364 5.06835C60.1303 4.97722 59.871 4.97722 59.6373 5.06835Z"
                        fill="#D9D9D9" />
                    <path
                        d="M30.0006 56.2612C30.0006 60.6331 33.7754 64.0492 38.1256 63.6141C52.6727 62.1594 67.3286 62.1594 81.8757 63.6141C86.2259 64.0492 90.0006 60.6331 90.0006 56.2612V36.4266C90.0006 35.7131 89.2747 35.2291 88.616 35.5036L65.3853 45.183C61.9391 46.6189 58.0622 46.6189 54.616 45.183L31.3853 35.5036C30.7266 35.2291 30.0006 35.7131 30.0006 36.4266V56.2612Z"
                        fill="#D9D9D9" />
                </svg>
            </div>
            <p class="campus_program_text">
                Apply for our campus MadScientist program
            </p>
            <p class="campus_program_desc">
                Apply for our campus MadScientist program
            </p>
            <a href="https://forms.gle/4eAcGeH5sa1mrXPC8" target="_blank" class="campus_program_action_btn">
                <div class="campus_program_action_btn_cover"></div>
                <p>Apply</p>
            </a>
            <a href="https://docs.google.com/document/d/19CSJ1h0gr521Zxg67cfo_-r0VKlqfszwpkSwi9YEPuk/edit?usp=sharing"
                target="_blank" class="campus_program_more_details_link">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6080_56870)">
                        <path
                            d="M6 4.8C6.33137 4.8 6.6 5.06863 6.6 5.4V8.4C6.6 8.73137 6.33137 9 6 9C5.66863 9 5.4 8.73137 5.4 8.4V5.4C5.4 5.06863 5.66863 4.8 6 4.8Z"
                            fill="#BABABA" />
                        <path
                            d="M6 4.2C6.33137 4.2 6.6 3.93137 6.6 3.6C6.6 3.26863 6.33137 3 6 3C5.66863 3 5.4 3.26863 5.4 3.6C5.4 3.93137 5.66863 4.2 6 4.2Z"
                            fill="#BABABA" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 11.4C8.98234 11.4 11.4 8.98234 11.4 6C11.4 3.01766 8.98234 0.6 6 0.6C3.01766 0.6 0.6 3.01766 0.6 6C0.6 8.98234 3.01766 11.4 6 11.4Z"
                            fill="#BABABA" />
                    </g>
                    <defs>
                        <clipPath id="clip0_6080_56870">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p>More details</p>
            </a>
        </div>
    </div>
</div>