import { Component, ElementRef, OnInit,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectionService } from 'src/app/services/projection.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css']
})
export class FeedComponent implements OnInit{

  feed:any[]=[];
  offset=0;
  fetchingData = false;
  constructor(private _projectionService:ProjectionService,
    private _generalService:GeneralService,
    private router: Router,
    private elementRef: ElementRef){

  }
  ngOnInit(): void {
    this.fetchProjections();
  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    
    if (scrollPercentage >= 70 && !this.fetchingData) {
      // console.log("User has scrolled to 70% of the content");
      this.fetchProjections(); 
    }
  }

  
  fetchProjections(){
    this.fetchingData = true;
    const param={
      "mad_id": localStorage.getItem("mad_id"),
      "range": 10,
      "offset": this.offset
    };
    this._projectionService.fetchProjections(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          if(data.response.list){
            // this.feed.push();
            this.feed = this.feed.concat(data.response.list);
            this.offset += 10; 
          }
          this.fetchingData = false;
        }
      }else{
        
      }
    }
    );
  }





}
