import { Component, Input, OnInit, ElementRef, ViewChild, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedActionsComponent } from 'src/app/popups/feed-actions/feed-actions.component';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ProjectionViewComponent } from 'src/app/popups/projection-view/projection-view.component';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectionService } from 'src/app/services/projection.service';
import { ReportComponent } from 'src/app/popups/report/report.component';
import { CommunityService } from 'src/app/services/community.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { EditProjectionComponent } from 'src/app/popups/edit-projection/edit-projection.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-projections',
  templateUrl: './projections.component.html',
  styleUrls: ['./projections.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectionsComponent implements OnInit {
  @Input() proj: any = {};
  currentPage = 1;
  totalPages = 0;
  progress = 0;
  style = 'width: 50%;height: 2.5px;background-color: #d9d9d9;';
  comment = '';
  isHidden = false;
  mad_id: any;
  public isLoading: boolean = true;

  @ViewChild('projectionLowerCaptionContent')
  projectionLowerCaptionContent!: ElementRef;
  showMoreBtn: Boolean = false;

  constructor(
    public dialog: MatDialog,
    private _generalService: GeneralService,
    private _projectionService: ProjectionService,
    private _communityService: CommunityService,
    private router: Router,
    private alertService: AlertService,
    private clipboard: Clipboard, 
    private snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.mad_id = localStorage.getItem('mad_id');
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
  }
  ngAfterContentInit(): void {
    setTimeout(() => this.showMoreBtnFn(), 0);
  }
  copyText(){
    console.log("Hi");
    this.clipboard.copy("Hi bhanu");
    this.snackBar.open('Text copied to clipboard!', 'Close', {
      duration: 2000,
    });
  }
  getHtmlString(data:string){
    const textWithLineBreaks = data.replace(/(?:\r\n|\r|\n)/g, ' <br>');
    // console.log(this.convertLinksToAnchors(textWithLineBreaks))
    return this.convertLinksToAnchors(textWithLineBreaks);
  }
  private convertLinksToAnchors(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const hyperlink = url.startsWith('www') ? `http://${url}` : url;
      return `<a href="${hyperlink}" target="_blank" class="link">${url}</a>`;
    });
  }
  share() {
    let urlTxt=btoa("projection|"+this.proj.projection_id);
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: this.proj.description,
        url: window.location.origin+"/home/public/"+urlTxt,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(urlTxt);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/public/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.alertService.showAlert("success","Link copied to Clipboard");
  }
  showMoreBtnFn() {
    if (this.projectionLowerCaptionContent) {
      const element = this.projectionLowerCaptionContent.nativeElement;

      const contentHeight: number = element.scrollHeight;
      const containerHeight: number = element.clientHeight;

      this.showMoreBtn = contentHeight > containerHeight;
    }
  }
  fullView() {
    const dialogRef = this.dialog.open(ProjectionViewComponent, {
      data: this.proj,
      width: '100%',
      height: '100%',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '10%', right: '0px', top: '0%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'projections',
      post_id: this.proj.projection_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
          //this.alertService.showAlert("success","You saved the projection");
        } else {
          //this.alertService.showAlert('failure', 'Error occured');
        }
      } else {
      }
    });
  }
  report() {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { post_type: 'projections', post_id: this.proj.projection_id },
      width: 'min(100%, 33rem)',
      height: 'min(90%, 42rem)',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  follow() {
    let projData = {
      following_id: this.proj.created_by,
    };
    this._communityService.followUser(projData).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status) {
          if (data.status.code == '00') {
            console.log('followed');
            //this.alertService.showAlert("success","You followed user");
          } else {
            //this.alertService.showAlert('failure', 'Error occured');
          }
        }
      } else {
      }
    });
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  openDialog(view: any) {
    if(view=='likes'){
      console.log(this.proj.total_likes)
      if(this.proj.total_likes==0){
        //this.alertService.showAlert('success', 'No appreciations yet');
        return;
      }
    }
    
    const dialogRef = this.dialog.open(FeedActionsComponent, {
      data: {
        view: view,
        projectionId: this.proj.projection_id,
        likesCount: this.proj.total_likes,
        commentsCount: this.proj.total_comments,
      },
      width: '100%',
      height: 'auto',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '10%', right: '0px', top: '0.8%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  onEdit(){
    const dialogRefIn = this.dialog.open(EditProjectionComponent, {
      data: {
        projection: this.proj,
      },
      width: '55%',
      height: '70%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '22.5%',
        'right': '0px',
        'top': '6.5%'
      }
    }
    );
    dialogRefIn.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });

  }
  onLike() {
    this._projectionService
      .likeProjection(this.proj.projection_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('liked');
            this.proj.total_likes += 1;
            this.proj.is_liked = 1;
            //this.alertService.showAlert("success","You appriciated projection");
          } else {
            //this.alertService.showAlert('failure', 'Error occured');
          }
        } else {
        }
      });
  }
  deleteProjection() {
    this._projectionService
      .deleteProjection(this.proj.projection_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.proj = {};
          }
        } else {
        }
      });
  }
  onUnLike() {
    // this._projectionService
    //   .unlikeProjection(this.proj.projection_id)
    //   .subscribe((data: any) => {
    //     if (data) {
    //       console.log(data);
    //       if (data.status && data.status.code == '00') {
    //         console.log('liked');
    //         this.proj.total_likes -= 1;
    //         this.proj.is_liked = 0;
    //         //this.alertService.showAlert("success","You unappriciated projection");
    //       } else {
    //         //this.alertService.showAlert('failure', 'Error occured');
    //       }
    //     } else {
    //     }
    //   });
  }
  addComment() {
    console.log(this.comment);
    let param = {
      projectionId: this.proj.projection_id,
      comment: this.comment,
      comment_by: localStorage.getItem('mad_id'),
    };
    this._projectionService.commentProjection(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('liked');
          this.comment = '';
          this.proj.total_comments += 1;          
          //this.alertService.showAlert("success","You unappriciated projection");
        }
      } else {
      }
    });
  }
  next() {
    this.currentPage += 1;
    this.progress = (this.currentPage / this.totalPages) * 100;
    this.style =
      'width: ' + this.progress + '%;height: 2.5px;background-color: #d9d9d9;';
  }
  back() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.progress = (this.currentPage / this.totalPages) * 100;
      this.style =
        'width: ' +
        this.progress +
        '%;height: 2.5px;background-color: #d9d9d9;';
    }
  }

  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    // console.log(progressData);
  }
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }
  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
    this.isLoading = false;
    console.log('(page-loaded)', pdf);
    console.log(pdf._pdfInfo.numPages);
    this.totalPages = pdf._pdfInfo.numPages;
    this.progress = (this.currentPage / this.totalPages) * 100;
    this.style =
      'width: ' + this.progress + '%;height: 2.5px;background-color: #d9d9d9;';
  }

  preload: string = 'auto';
  api: VgApiService = new VgApiService();
  playing: boolean = false;

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(() => {
      // Set the video to the beginning
      this.api.getDefaultMedia().currentTime = 0;
    });
  }
  pause() {
    if (this.playing) {
      this.playing = !this.playing;
      this.api?.pause();
    } else {
      this.playing = !this.playing;
      this.api?.play();
    }
  }
  back10Sec() {
    this.api.getDefaultMedia().currentTime =
      this.api.getDefaultMedia().currentTime - 10;
    this.api?.play();
    this.playing = true;
  }
  front10Sec() {
    this.api.getDefaultMedia().currentTime =
      this.api.getDefaultMedia().currentTime + 10;
    this.api?.play();
    this.playing = true;
  }
}
