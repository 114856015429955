import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MessagesComponent implements OnInit,AfterViewChecked{
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  screen="list";
  messageText="";
  messagesList:any=[]
  chatList:any=[];
  logged_mad_id:any;
  current_chat:any;
  username:any;
  userData:any;

  constructor(
    private _userService: UserService,
    private router: Router,
    private _generalService: GeneralService,
    private _chatService: ChatService,
    private route: ActivatedRoute,

  ) {
  }
  ngOnInit(): void {
    const username = this.route.snapshot.paramMap.get('username');
    this.logged_mad_id = localStorage.getItem('mad_id');
    if(username){
      console.log("username");
      console.log(username);
      this.username=username;
      this.getuserData();
    }else{
      this.fetchChats();
    }
  }
  getuserData() {
    this._userService.getUserDataByUsername(this.username)
      .subscribe((data: any) => {
        if (data) {
          this.userData = data;
          let cchat={
            "mad_id": this.userData.mad_id,
            "user_name": this.userData.user_name,
            "first_name": this.userData.first_name,
            "last_name": this.userData.last_name,
            "profile_photo_path": this.userData.profile_photo_path,
            "tagline": this.userData.tagline,
            "last_message_time": "",
            "message_content": ""
          }
          this.current_chat=cchat;
          this.screen="single";
          this.messageText="";
          this.messagesList=[];
          this.fetchChatMessages(cchat.mad_id);
          this.markAsRead(cchat.mad_id);
        } else {

        }
      }
      );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  getClass(mad_id:any){
    if(mad_id==this.logged_mad_id){
      return "message message-self";
    }else{
      return "message";
    }

  }
  private scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }
  getHtmlString(data:string){
    const textWithLineBreaks = data.replace(/(?:\r\n|\r|\n)/g, ' <br>');
    // console.log(this.convertLinksToAnchors(textWithLineBreaks))
    return this.convertLinksToAnchors(textWithLineBreaks);
  }
  private convertLinksToAnchors(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const hyperlink = url.startsWith('www') ? `http://${url}` : url;
      return `<a href="${hyperlink}" target="_blank" class="link">${url}</a>`;
    });
  }
  openChat(chat:any){
    this.current_chat=chat;
    this.screen="single";
    this.messageText="";
    this.messagesList=[];
    this.fetchChatMessages(chat.mad_id);
    this.markAsRead(chat.mad_id);
  }
  getChatClass(count:any){
    if(count>0){
      return "message-outer-active";
    }else{
      return "message-outer";
    }
  }
  back(){
    this.screen="list";
    this.messageText="";
    this.current_chat=null;
    this.fetchChats();
  }
  sendMessage(){
    let param ={
      "message":this.messageText
    }
    this._chatService.sendMessage(this.current_chat.mad_id,param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        this.messagesList = this.messagesList.concat([data]);
        this.messageText="";
      }else{
        
      }
    }
    );
  }
  fetchChats(){
    this._chatService.fetchChats()
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        this.chatList=data;
      }else{
        
      }
    }
    );
  }
  fetchChatMessages(mad_id:any){
    this._chatService.fetchChatMessages(mad_id)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        this.messagesList=data;
      }else{
        
      }
    }
    );
  }
  markAsRead(mad_id:any){
    let param={
      "senderId":mad_id
    }
    this._chatService.markAsRead(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
      }else{
        
      }
    }
    );
  }
}
