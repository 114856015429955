import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForumViewComponent } from 'src/app/popups/forum-view/forum-view.component';
import { ForumService } from 'src/app/services/forum.service';

@Component({
  selector: 'app-forums',
  templateUrl: './forums.component.html',
  styleUrls: ['./forums.component.css']
})
export class ForumsComponent implements OnInit{
  forumList:any=[];
  offset=0;
  fetchingData = false;

  constructor(private _forumService:ForumService) {}
  ngOnInit(): void {
    this.getForums();
  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    
    if (scrollPercentage >= 70 && !this.fetchingData) {
      // console.log("User has scrolled to 70% of the content");
      this.getForums(); 
    }
  }
  getForums(){
    this.fetchingData = true;
    let param={
      "mad_id":localStorage.getItem("mad_id"),
      "range":10,
      "offset":this.offset
    }
    this._forumService.getForums(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          this.forumList = this.forumList.concat(data.response.list);
          this.offset += 10; 
        }
      }else{
        
      }
      this.fetchingData = false;
    }
    );

  }
}
