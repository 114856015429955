<div class="community-box no-scrollbar" (scroll)="scrollHandler($event)" *ngIf="communityList.length>0">
  <div class="community-people" *ngFor="let user of communityList;let i=index">
    <div class="person-details" (click)="navigateToProfile(user.user_name)">
      <div class="people-profile-picture-box">
        <img [src]="getDownloadUrl(user.profile_photo_path)" alt="" />
      </div>
      <p class="people-name">
        {{ user.first_name }} {{ user.last_name }}
        <!-- <span class="people-verified">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 4C0 1.79086 1.79086 0 4 0H7.5H10C12.2091 0 14 1.79086 14 4V10C14 12.2091 12.2091 14 10 14H4C1.79086 14 0 12.2091 0 10V4Z"
                            fill="#10776B" />
                        <path
                            d="M9.104 4.42896C9.488 4.42896 9.836 4.5096 10.148 4.67088C10.468 4.83216 10.74 5.0472 10.964 5.316C11.196 5.57712 11.372 5.8728 11.492 6.20304C11.62 6.53328 11.684 6.86352 11.684 7.19376V10.5H10.592V7.19376C10.592 6.9864 10.556 6.78288 10.484 6.5832C10.412 6.37584 10.308 6.19152 10.172 6.03024C10.044 5.86128 9.888 5.72688 9.704 5.62704C9.52 5.51952 9.32 5.46576 9.104 5.46576C8.888 5.46576 8.688 5.51568 8.504 5.61552C8.32 5.71536 8.16 5.84592 8.024 6.0072C7.888 6.16848 7.78 6.34896 7.7 6.54864C7.628 6.74832 7.592 6.94416 7.592 7.13616V10.5H6.512V7.19376C6.512 6.9864 6.476 6.78288 6.404 6.5832C6.332 6.37584 6.228 6.19152 6.092 6.03024C5.964 5.86128 5.808 5.72688 5.624 5.62704C5.44 5.51952 5.24 5.46576 5.024 5.46576C4.808 5.46576 4.608 5.51568 4.424 5.61552C4.24 5.71536 4.08 5.84592 3.944 6.0072C3.808 6.16848 3.7 6.34896 3.62 6.54864C3.548 6.74832 3.512 6.94416 3.512 7.13616V10.5H2.42V7.13616C2.42 6.8136 2.484 6.49488 2.612 6.18C2.74 5.85744 2.916 5.5656 3.14 5.30448C3.372 5.04336 3.648 4.83216 3.968 4.67088C4.288 4.5096 4.64 4.42896 5.024 4.42896C5.456 4.42896 5.844 4.53264 6.188 4.74C6.54 4.94736 6.828 5.21232 7.052 5.53488C7.284 5.21232 7.572 4.94736 7.916 4.74C8.268 4.53264 8.664 4.42896 9.104 4.42896Z"
                            fill="white" />
                        <path d="M2 2.5H12V3.83333H2V2.5Z" fill="white" />
                    </svg>
                </span> -->
      </p>
      <p class="people-tagline">{{ user.tagline }}</p>
    </div>
    <p class="people-follower-count-box">
      <span class="people-follower-count">{{
        user.followers | socialCount
      }}</span>
      followers
    </p>
    <!-- use this class  "people-action-button--unfollow"   for green following button-->
    <button class="people-action-button" *ngIf="user.is_following==0" (click)="follow(user.mad_id)">
      <div >Follow</div>
    </button>
    <button class="people-action-button" *ngIf="user.is_following==1" (click)="unfollow(user.mad_id)">
      <div >Unfollow</div>
    </button>
  </div>
</div>
<div class="empty_community_box" *ngIf="communityList.length==0">
  <app-loader *ngIf="fetchingData"></app-loader>
  <div *ngIf="!fetchingData && tab!='community'" class="empty_community">
      <div class="empty_community_icon">
          <svg width="83" height="67" viewBox="0 0 83 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.508532 65.7998C0.335663 66.0152 0.370091 66.3299 0.58543 66.5027C0.800769 66.6756 1.11547 66.6412 1.28834 66.4258L0.508532 65.7998ZM28.6195 31.5814L28.939 31.1967L28.5479 30.8719L28.2296 31.2683L28.6195 31.5814ZM56.3406 54.6023L56.0212 54.987L56.5302 55.4097L56.7979 54.8046L56.3406 54.6023ZM80.5679 0.706411C80.4683 0.448849 80.1788 0.32078 79.9213 0.420361L75.724 2.04313C75.4665 2.14271 75.3384 2.43223 75.438 2.68979C75.5376 2.94735 75.8271 3.07542 76.0847 2.97584L79.8155 1.53338L81.258 5.26424C81.3576 5.52181 81.6471 5.64987 81.9046 5.55029C82.1622 5.45071 82.2903 5.16119 82.1907 4.90363L80.5679 0.706411ZM1.28834 66.4258L29.0094 31.8944L28.2296 31.2683L0.508532 65.7998L1.28834 66.4258ZM28.3001 31.966L56.0212 54.987L56.6601 54.2177L28.939 31.1967L28.3001 31.966ZM56.7979 54.8046L80.5588 1.08899L79.6443 0.684451L55.8834 54.4001L56.7979 54.8046Z" fill="#D9D9D9"/>
              <rect x="29.0469" y="5.70508" width="22.295" height="22.295" rx="11.1475" fill="#121212" stroke="#7B7B7B"/>
              <path d="M17.3984 46.7258C17.3984 36.9361 25.3346 29 35.1242 29H45.2627C55.0524 29 62.9885 36.9361 62.9885 46.7258C62.9885 52.4657 58.3354 57.1188 52.5955 57.1188H27.7914C22.0515 57.1188 17.3984 52.4657 17.3984 46.7258Z" fill="#121212" stroke="#7B7B7B"/>
          </svg>
          <p *ngIf="tab=='followers'">You have no followers</p>
          <p *ngIf="tab=='following'">You’re not following anyone</p>
      </div>
      <div class="empty_community_text_box">
          <p *ngIf="tab=='followers'">Build your community on MadScientist by sharing relevant content</p>
          <p *ngIf="tab=='following'">Explore the projections, Writeups, Forums. Find tech enthusiasts to follow their work and their superhuman work</p>
          <button class="empty_community_action_btn">
              <div class="empty_community_action_btn_wrapper" *ngIf="tab=='followers'" (click)="createPost()">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5535_54238)">
                      <rect y="0.112793" width="20" height="20" rx="4" fill="white" fill-opacity="0.14"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7498 6.11182C10.7498 5.6976 10.414 5.36182 9.99976 5.36182C9.58554 5.36182 9.24976 5.6976 9.24976 6.11182V9.36084H6C5.58579 9.36084 5.25 9.69663 5.25 10.1108C5.25 10.5251 5.58579 10.8608 6 10.8608H9.24976V14.1118C9.24976 14.526 9.58554 14.8618 9.99976 14.8618C10.414 14.8618 10.7498 14.526 10.7498 14.1118V10.8608H14C14.4142 10.8608 14.75 10.5251 14.75 10.1108C14.75 9.69663 14.4142 9.36084 14 9.36084H10.7498V6.11182Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_5535_54238">
                      <rect width="20" height="20" fill="white" transform="translate(0 0.112793)"/>
                      </clipPath>
                      </defs>
                  </svg>
                  <p>create post</p>
              </div>
              <div class="empty_community_action_btn_wrapper" *ngIf="tab=='following'" (click)="explore()">
                <!-- <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5535_54238)">
                    <rect y="0.112793" width="20" height="20" rx="4" fill="white" fill-opacity="0.14"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7498 6.11182C10.7498 5.6976 10.414 5.36182 9.99976 5.36182C9.58554 5.36182 9.24976 5.6976 9.24976 6.11182V9.36084H6C5.58579 9.36084 5.25 9.69663 5.25 10.1108C5.25 10.5251 5.58579 10.8608 6 10.8608H9.24976V14.1118C9.24976 14.526 9.58554 14.8618 9.99976 14.8618C10.414 14.8618 10.7498 14.526 10.7498 14.1118V10.8608H14C14.4142 10.8608 14.75 10.5251 14.75 10.1108C14.75 9.69663 14.4142 9.36084 14 9.36084H10.7498V6.11182Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_5535_54238">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.112793)"/>
                    </clipPath>
                    </defs>
                </svg> -->
                <p>Explore People</p>
            </div>
          </button>
      </div>
  </div>
</div>
