<div class="feed">
  <div class="settings_box">
    <div class="settings_navbar no-scrollbar">
      <div class="settings_navbar_links">
        <a
          (click)="changeTab('edit_profile')"
          [ngClass]="
            current_view == 'edit_profile'
              ? 'settings_navbar_link settings_navbar_link--active'
              : 'settings_navbar_link'
          "
        >
          <p>Edit Profile</p>
        </a>
        <a
          (click)="changeTab('notification')"
          [ngClass]="
            current_view == 'notification'
              ? 'settings_navbar_link settings_navbar_link--active'
              : 'settings_navbar_link'
          "
        >
          <p>Notifications</p>
        </a>
        <a
          (click)="changeTab('account')"
          [ngClass]="
            current_view == 'account'
              ? 'settings_navbar_link settings_navbar_link--active'
              : 'settings_navbar_link'
          "
        >
          <p>Account</p>
        </a>
      </div>
      <div class="settings_navbar_helper_box">
        <p class="settings_navbar_helper">Edit your profile as you like.</p>
      </div>
    </div>
    <div class="settings">
      <app-edit-profile
        [userData]="userData"
        *ngIf="current_view == 'edit_profile'"
      ></app-edit-profile>
      <app-notifications-setting
        *ngIf="current_view == 'notification'"
        style="width: 100%"
      ></app-notifications-setting>
      <app-account-setting
        [userData]="userData"
        *ngIf="current_view == 'account'"
        style="width: 100%"
      ></app-account-setting>
    </div>
  </div>
</div>
