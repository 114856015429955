import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddForumComponent } from 'src/app/popups/add-forum/add-forum.component';
import { AddProjectionComponent } from 'src/app/popups/add-projection/add-projection.component';
import { AddWriteupComponent } from 'src/app/popups/add-writeup/add-writeup.component';
import { CreatePostComponent } from 'src/app/popups/create-post/create-post.component';
import { CommunityService } from 'src/app/services/community.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-community-box',
  templateUrl: './community-box.component.html',
  styleUrls: ['./community-box.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunityBoxComponent {
  @Input() communityList: any = [];
  @Input() tab: string = "";
  @Input() fetchingData=false;
  @Output() scrollEvent = new EventEmitter<string>();
  @Output() exploreEvent = new EventEmitter<string>();
  @Output() followEvent = new EventEmitter<string>();

  constructor(private _generalService: GeneralService,
    public dialog: MatDialog,
    private _communityService: CommunityService,
    private router: Router) {

  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    
    console.log("scroll");
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    
    if (scrollPercentage >= 70 && !this.fetchingData) {
      // console.log("User has scrolled to 70% of the content");
      // this.changeTab(this.current_tab,true);
      this.scrollEvent.emit();
    }
  }
  explore(){
    this.exploreEvent.emit();
  }
  createPost() {
    const dialogRef = this.dialog.open(CreatePostComponent, {
      data: {
        animal: 'panda'
      },
      width: '55%',
      height: '70%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '22.5%',
        'right': '0px',
        'top': '6.5%'
      }
    }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == "projection") {
        const dialogRefIn = this.dialog.open(AddProjectionComponent, {
          data: {
            type: 'new',
            projection:""
          },
          width: '55%',
          height: '70%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '22.5%',
            'right': '0px',
            'top': '6.5%'
          }
        }
        );
      } else if (result == "writeup") {
        const dialogRefIn = this.dialog.open(AddWriteupComponent, {
          data: {
            type: 'new',
            writeup:""
          },
          width: '80%',
          height: '85%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '10%',
            'right': '0px',
            'top': '3.5%'
          }
        }
        );
      } else if (result == "forum") {
        const dialogRefIn = this.dialog.open(AddForumComponent, {
          data: {
            type: 'new',
            forum:""
          },
          width: '60%',
          height: '60%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '20%',
            'right': '0px',
            'top': '10%'
          }
        }
        );
      }
    });
  }
  follow(mad_id:any){
    let projData = {
      following_id: mad_id
    }
    this._communityService.followUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("followed");
              this.followEvent.emit("follow");
              const index = this.communityList.findIndex((record: any) => record.mad_id == mad_id);
              if (index !== -1) {
                  this.communityList[index].is_following = 1;
              }
            }
          }
        }else{
          
        }
      }
      );
  }
  unfollow(mad_id:any){
    let projData = {
      following_id: mad_id
    }
    this._communityService.unfollowUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("followed");
              this.followEvent.emit("unfollow");
              const index = this.communityList.findIndex((record: any) => record.mad_id == mad_id);
              if (index !== -1) {
                  this.communityList[index].is_following = 0;
              }
            }
          }
        }else{
          
        }
      }
      );
  }

  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  onClick(value: any) {
    
  }
  navigateToProfile(username:any){
    let path="/home/profile/"+username;
    this.router.navigate([path]);
  }
}
