<!-- <quill-editor [modules]="modules" [theme]="'bubble'" [styles]="{'background-color': 'white','height':'200px'}" (onContentChanged)="onContentChanged($event)"></quill-editor> -->
<div class="writeup-editor" *ngIf="!submitFlag">
  <!-- DISCARD POPUP -->
  <div class="discard_popup_box" *ngIf="discardFlag">
    <div class="discard_popup">
      <h2 class="discard_title">Discard</h2>
      <div class="discard_text_box">
        <h2 class="discard_text">
          Are you sure you want to discard the Projection?
        </h2>
        <p class="discard_text_desc">
          Your Projection won't be saved if you leave
        </p>
      </div>
      <div class="discard_action_btns">
        <button
          class="discard_action_btn discard_action_btn--yes"
          (click)="close()"
        >
          <p>Yes</p>
        </button>
        <button
          class="discard_action_btn discard_action_btn--no"
          (click)="discardFlag = false"
        >
          <p>No</p>
        </button>
      </div>
    </div>
  </div>

  <div class="blurred-background"></div>
  <div class="container">
    <div class="wrapper">
      <div class="navbar">
        <img class="logo" src="assets/logo.png" />
        <div class="discard-post">
          <svg
            class="frame-3561"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526Z"
              fill="white"
            />
          </svg>
          <div class="discard" (click)="discardFlag = true">Discard</div>
        </div>
      </div>
      <div class="publish-main" (click)="post()">
        <div class="publish">Publish</div>
        <svg
          class="frame-3359"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25284 14.7916C4.79047 15.2369 3.99997 14.9214 4 14.2916L4.00056 1.70837C4.00059 1.07853 4.79112 0.763122 5.25345 1.20849L11.785 7.50036C12.0717 7.77647 12.0717 8.22414 11.785 8.50023L5.25284 14.7916Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <div class="editor">
      <div class="editor-1">
        <div class="editor-2">
          <!-- <div class="title">
                        <input class="title" type="text" name="title">
                    </div> -->
          <input
            class="title"
            type="text"
            name="title"
            placeholder="Enter Title here"
            [(ngModel)]="title"
            maxlength="180"
          />
          <span class="count">{{ 180 - title.length }}</span>
        </div>

        <hr
          class="hr-line"
          style="
            border-color: #515151;
            border-width: 1px;
            margin: 0;
            width: 100%;
          "
        />
      </div>
      <div class="editor-3">
        <quill-editor
          style="width: 100%; height: 100%"
          [modules]="modules"
          [theme]="'bubble'"
          [styles]="{
            'background-color': 'transparent',
            height: '100%',
            color: 'white',
            'font-family': 'LibreBaskerville-Bold',
            'font-size': '20px',
            'font-style': 'normal'
          }"
          (onContentChanged)="onContentChanged($event)"
        ></quill-editor>
        <span class="count">{{ maxLength - characterCount }}</span>
        <!-- <div class="content">
                    
                </div> -->
      </div>
    </div>
    <!-- <div class="scroll">
        </div>
        <div class="scroll-2">
        </div> -->
  </div>
</div>
<div *ngIf="submitFlag">
  <div class="black_bg_box">
    <div class="upload_file_box_wrapper popUpWrapper">
      <div class="progress_bar_box">
        <div class="progress_bar"></div>
      </div>
      <div class="upload_file_box" *ngIf="isLoading && !successFlg">
        <div class="upload_file_wrapper">
          <div class="upload_file">
            <!-- <svg width="35" height="34" viewBox="0 0 35 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17" r="16" stroke="#363636" stroke-width="2" />
                            <path
                                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                                stroke="#10776B" stroke-width="2" stroke-linecap="round" />
                        </svg> -->
            <svg
              width="35"
              height="34"
              viewBox="0 0 35 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17"
                r="16"
                stroke="#363636"
                stroke-width="2"
              />
              <path
                id="loaderPath"
                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                stroke="#10776B"
                stroke-width="2"
                stroke-linecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="0 17 17"
                  to="360 17 17"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
            <div class="upload_file_text_group">
              <p class="upload_file_text">Uploading.</p>
              <p class="upload_file_desc">
                Please wait while projecting to MadScientist
              </p>
            </div>
          </div>
        </div>
        <div class="review_text_box">
          <p>
            Our team will verify your content. We may delete if it's not
            relevant to Madscientist, or any misinformation.
          </p>
        </div>
      </div>
      <div class="upload_success_box" *ngIf="successFlg">
        <div class="upload_success">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="24"
              cy="24"
              r="24"
              fill="url(#paint0_linear_7237_12527)"
            />
            <path
              d="M15.5 23.7579L20.6233 28.6099C21.1611 29.1192 22.029 29.1314 22.5825 28.6373L34.5 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7237_12527"
                x1="24"
                y1="0"
                x2="24"
                y2="48"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#10776B" />
                <stop offset="1" stop-color="#009DA7" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div class="upload_success_text">
            <p>Projected successfully</p>
          </div>
        </div>
      </div>
      <div class="edit_btn_box">
        <button class="edit_btn">
          <p>Edit</p>
        </button>
      </div>
    </div>
  </div>
</div>
