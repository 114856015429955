import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { PostComponent } from './components/post/post.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component:LandingPageComponent},
  {
    path: 'home',
    loadChildren: () => import('./home-routing.module').then(m => m.HomeRoutingModule) // This loads the customers module on demand
  },
  { path: 'home', component: HomeComponent },
  { path: 'signin', component: SignInComponent},
  { path: 'signup', component: SignUpComponent},
  {path:'writeup',component:PostComponent},
  {path:'resetpassword',component:ResetPasswordComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), HomeRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
