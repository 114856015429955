<!-- <div class="writeup"> -->
<div class="writeup_upper_box" *ngIf="writeup?.writeup_id">
  <button class="author" (click)="navigateToProfile(writeup.user_name)">
    <div class="author_img">
      <img
      [src]="getDownloadUrl(writeup?.profile_photo_path)"
        alt=""
      />
    </div>
    <div class="author_details">
      <div class="author_username">
        <h3>{{ writeup.first_name }} {{ writeup.last_name }}</h3>
      </div>
      <div class="author_tagline">
        <h4>{{ writeup.tagline }}</h4>
      </div>
    </div>
  </button>
  <div class="sharelink_box">
    <button class="sharelink" (click)="share()">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7322_54462)">
          <path
            d="M7.75 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V12.25"
            stroke="white"
            stroke-linecap="round"
          />
          <path
            d="M13.107 5.4V3.39998C13.107 3.23517 13.2952 3.1411 13.427 3.23997L17.6941 6.43999C17.8008 6.51999 17.8008 6.68001 17.6941 6.76001L13.427 9.96003C13.2952 10.0589 13.107 9.96483 13.107 9.80002V7.8C8.92939 7.38227 6.87284 11.1756 6.14603 13.8805C6.08786 14.097 5.75632 14.0877 5.73133 13.8649C5.02031 7.52526 9.51386 5.21089 13.107 5.4Z"
            stroke="white"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_7322_54462">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
</div>
<button class="writeup_middle_box" *ngIf="writeup?.writeup_id">
  <div class="writeup_title_box">
    <h2 class="writeup_title" (click)="openDialog(writeup)">{{ writeup.title }}</h2>
    <div class="writeup_actions_box_menu_box" (mouseleave)="isHidden = false">
      <h4 class="timestamp">{{ writeup.created_on | timeAgo }}</h4>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="isHidden = !isHidden"
        *ngIf="writeup.created_by != mad_id"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.25 14.25C6.49264 14.25 7.5 13.2426 7.5 12C7.5 10.7574 6.49264 9.75 5.25 9.75C4.00736 9.75 3 10.7574 3 12C3 13.2426 4.00736 14.25 5.25 14.25ZM14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12ZM21 12C21 13.2426 19.9926 14.25 18.75 14.25C17.5074 14.25 16.5 13.2426 16.5 12C16.5 10.7574 17.5074 9.75 18.75 9.75C19.9926 9.75 21 10.7574 21 12Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
      <div class="projectionNonAuthorMenu" *ngIf="isHidden">
        <!-- <div class="projectionMenuItem" *ngIf="writeup.created_by == mad_id">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2230_19779)">
              <path
                d="M15.3138 4.70711C14.9233 4.31658 14.2901 4.31658 13.8996 4.70711L5.71083 12.8959L4.70719 15.3137L7.12504 14.3101L15.3138 6.12132C15.7043 5.7308 15.7043 5.09763 15.3138 4.70711Z"
                fill="#BABABA" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4C1 2.34315 2.34315 1 4 1Z"
                fill="#BABABA" />
            </g>
            <defs>
              <clipPath id="clip0_2230_19779">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Edit
        </div>
        <div class="projectionMenuItem" style="color: #ff0000; font-weight: 500" *ngIf="writeup.created_by == mad_id"
          (click)="deleteWriteup()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2230_20703)">
              <path
                d="M7.5 1C7.5 0.447754 7.94775 0 8.5 0H11.5C12.0522 0 12.5 0.447754 12.5 1V2H19C19.5522 2 20 2.44775 20 3C20 3.55225 19.5522 4 19 4H1C0.447754 4 0 3.55225 0 3C0 2.44775 0.447754 2 1 2H7.5V1Z"
                fill="#FF0000" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 8C0 6.89543 0.895431 6 2 6H18C19.1046 6 20 6.89543 20 8V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V8ZM2 7H18C18.5523 7 19 7.44772 19 8V18C19 18.5523 18.5523 19 18 19H2C1.44772 19 1 18.5523 1 18V8C1 7.44772 1.44772 7 2 7Z"
                fill="#FF0000" />
            </g>
            <defs>
              <clipPath id="clip0_2230_20703">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>

          Delete
        </div> -->
        <!-- <div class="projectionMenuItem" *ngIf="proj.created_by!=mad_id" (click)="follow()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.59971 10C0.216279 9.24993 0 8.40022 0 7.5C0 4.46243 2.46243 2 5.5 2H14.5C17.5376 2 20 4.46243 20 7.5C20 8.40022 19.7837 9.24993 19.4003 10C19.7837 10.7501 20 11.5998 20 12.5C20 15.5376 17.5376 18 14.5 18H5.5C2.46243 18 0 15.5376 0 12.5C0 11.5998 0.216279 10.7501 0.59971 10ZM5.5 3H14.5C16.9853 3 19 5.01472 19 7.5C19 8.0263 18.9096 8.5315 18.7436 9.00094C17.7348 7.77889 16.2084 7 14.5 7H5.5C3.79163 7 2.26517 7.7789 1.25639 9.00094C1.09035 8.5315 1 8.0263 1 7.5C1 5.01472 3.01472 3 5.5 3ZM18.7436 10.9991C18.9096 11.4685 19 11.9737 19 12.5C19 14.9853 16.9853 17 14.5 17H5.5C3.01472 17 1 14.9853 1 12.5C1 11.9737 1.09035 11.4685 1.25639 10.9991C2.26517 12.2211 3.79163 13 5.5 13H14.5C16.2084 13 17.7348 12.2211 18.7436 10.9991ZM18.2422 10C17.435 11.206 16.0602 12 14.5 12H5.5C3.93979 12 2.56504 11.206 1.75777 10C2.56504 8.79401 3.93979 8 5.5 8H14.5C16.0602 8 17.435 8.79402 18.2422 10Z"
              fill="#BABABA" />
          </svg>
          Follow
        </div> -->
        <div class="projectionMenuItem" (click)="save()" *ngIf="writeup.created_by != mad_id">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2223_6312)">
              <path d="M9.5 5V10H7.11325L10 15L12.8868 10H10.5V5H9.5Z" fill="#BABABA" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4C1 2.34315 2.34315 1 4 1Z"
                fill="#BABABA" />
            </g>
            <defs>
              <clipPath id="clip0_2223_6312">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>

          Save
        </div>
        <div class="projectionMenuItem report" *ngIf="writeup.created_by != mad_id" (click)="report()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2223_7588)">
              <path d="M8.9792 8.33603L9.376 11.7901H10.4448L10.816 8.33603V4.75433H8.9792V8.33603Z"
                fill="currentColor" />
              <path
                d="M9.056 14.5183C9.26507 14.7065 9.54667 14.8006 9.9008 14.8006C10.2507 14.8006 10.528 14.7065 10.7328 14.5183C10.9419 14.3257 11.0464 14.0703 11.0464 13.7523C11.0464 13.4342 10.944 13.1811 10.7392 12.9929C10.5344 12.8003 10.2549 12.704 9.9008 12.704C9.5424 12.704 9.2608 12.798 9.056 12.9862C8.8512 13.1744 8.7488 13.4297 8.7488 13.7523C8.7488 14.0748 8.8512 14.3302 9.056 14.5183Z"
                fill="currentColor" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 4.19994C0 1.88038 1.79086 0 4 0H16C18.2091 0 20 1.88038 20 4.19994V17.898C20 19.3836 18.5709 20.3995 17.2572 19.8477L15.7152 19.2001C15.2428 19.0017 14.7385 18.8997 14.2297 18.8997H4C1.79086 18.8997 0 17.0193 0 14.6998V4.19994ZM4 1.04998H16C17.6569 1.04998 19 2.46027 19 4.19994V17.898C19 18.6408 18.2855 19.1487 17.6286 18.8728L16.0866 18.2252C15.496 17.9772 14.8658 17.8497 14.2297 17.8497H4C2.34315 17.8497 1 16.4394 1 14.6998V4.19994C1 2.46027 2.34315 1.04998 4 1.04998Z"
                fill="currentColor" />
            </g>
            <defs>
              <clipPath id="clip0_2223_7588">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>

          Report
        </div>
      </div>
    </div>
  </div>
  <div class="writeup_desc_box" (click)="openDialog(writeup)">
    <p class="writeup_desc">{{ writeup.data }}</p>
  </div>
</button>
<div class="writeup_bottom_box" *ngIf="writeup?.writeup_id">
  <div class="writeup_stats">
    <div class="views">
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9986 8.85097C18.0119 9.11706 17.7947 9.33318 17.5283 9.33318C17.2618 9.33318 17.0473 9.11703 17.0324 8.85102C16.9804 7.92145 16.7782 7.0063 16.4334 6.14395C16.029 5.13283 15.4363 4.21411 14.6892 3.44024C13.942 2.66637 13.055 2.0525 12.0788 1.63368C11.1026 1.21487 10.0563 0.999307 8.99961 0.999307C7.94296 0.999307 6.89666 1.21487 5.92044 1.63369C4.94423 2.0525 4.05722 2.66637 3.31005 3.44024C2.56289 4.21411 1.97021 5.13283 1.56585 6.14395C1.22098 7.0063 1.01885 7.92145 0.966839 8.85102C0.951955 9.11703 0.73738 9.33318 0.470953 9.33318C0.204526 9.33318 -0.0127121 9.11706 0.000579156 8.85097C0.0535692 7.79009 0.281107 6.74514 0.674471 5.76153C1.12732 4.62917 1.79107 3.60029 2.62782 2.73363C3.46458 1.86696 4.45795 1.17948 5.55122 0.710446C6.6445 0.24141 7.81626 0 8.99961 0C10.183 0 11.3547 0.24141 12.448 0.710446C13.5413 1.17948 14.5346 1.86696 15.3714 2.73363C16.2082 3.60029 16.8719 4.62917 17.3247 5.76153C17.7181 6.74514 17.9457 7.79009 17.9986 8.85097ZM8.846 4.66932C8.55749 4.67904 8.27584 4.71684 8.00351 4.78019L7.99957 4.78111C5.99023 5.25047 4.48952 7.11042 4.48952 9.33341C4.48952 11.9107 6.50672 14 8.99505 14C11.4834 14 13.5006 11.9107 13.5006 9.33341C13.5006 8.5377 13.3083 7.78851 12.9693 7.13296C12.6443 6.50472 11.8298 6.51789 11.0917 6.52982L11.0916 6.52982C10.9925 6.53142 10.8948 6.533 10.7998 6.533C9.8052 6.533 8.99877 5.69876 8.99734 4.66911C8.99733 4.66785 8.99631 4.66682 8.99505 4.66682C8.94518 4.66682 8.89549 4.66766 8.846 4.66932ZM8.20964 5.75996C8.6255 6.78839 9.6113 7.533 10.7998 7.533C10.9032 7.533 11.0085 7.53129 11.1058 7.52972L11.1058 7.52972L11.1243 7.52942C11.2298 7.52772 11.3273 7.52627 11.4227 7.52652C11.6166 7.52705 11.7703 7.53491 11.8951 7.55412C12.0077 7.57144 12.0645 7.5934 12.0874 7.60479C12.3503 8.11775 12.5006 8.70526 12.5006 9.33341C12.5006 11.3918 10.8983 13 8.99505 13C7.09182 13 5.48952 11.3918 5.48952 9.33341C5.48952 7.56599 6.67083 6.13049 8.20964 5.75996Z"
          fill="#EAEAEA"
        />
      </svg>
      <span class="count">{{ writeup.total_views | socialCount }}</span>
    </div>
    <div class="likes">
      <span class="count">{{ writeup.total_likes | socialCount }}</span>
      <p>Appreciations</p>
    </div>
  </div>
  <div class="read_more_btn_box">
    <button class="read_more_btn" (click)="openDialog(writeup)">
      <p>Read More</p>
    </button>
  </div>
</div>
<!-- </div> -->
